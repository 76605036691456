.action-menu {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
}

.stick-to-bottom {
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    z-index: 80;
    padding: 2px;
}
