.item-icon {
    max-width: 128px;
    width: 100%;
    max-height: 128px;
    height: auto;
}

.w-col-50 {
    width: 50%;
}
