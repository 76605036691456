.w-col-10 {
    width: 10%;
}

.w-col-30 {
    width: 30%;
}

.w-col-40 {
    width: 40%;
}
