* {
    font-family: 'Exo 2', sans-serif;
}

.modal.fade.in {
    opacity: 1;
}

.modal.in .modal-dialog {
    transform: translate(0, 0);
}

.modal-backdrop.in {
    opacity: 0.5;
}

.modal {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.modal-lg {
    max-width: 1140px !important;
    max-height: 95% !important;
    width: 95% !important;
    height: 95% !important;
}

.modal-content {
    -webkit-box-shadow: -1px 1px 5px 9px #333;
    -moz-box-shadow: -1px 1px 5px 9px #333;
    box-shadow: -1px 1px 50px 1px #333;
    width: 100% !important;
    height: 100% !important;
}

.modal-body img {
    width: auto;
    height: 150px;
}

.modal-dialog {
    overflow-y: initial !important;
}

.modal-body {
    overflow-y: auto;
}

.soTooltip {
    color: white !important;
    background-color: #000 !important;
}
.soTooltip.place-top .soTooltip:after {
    border-top-color: #000 !important;
    border-top-style: solid !important;
    border-top-width: 6px !important;
}
