/* Disable selection */
body {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/* Disable highlighting */
body {
    -webkit-tap-highlight-color: transparent;
}

/* Disable callouts IOS Menus */
body {
    -webkit-touch-callout: none;
}

/* Disable pinch zooms on IOS  */
body {
    -webkit-text-size-adjust: none;
}

body {
    -webkit-overflow-scrolling: auto;
}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}

html,
body {
    overscroll-behavior-x: none;
}

.cursor-pointer {
    cursor: pointer;
}

html,
#root {
    background-color: #f4f6f9;
}
