.img-container {
    margin: auto;
}

.responsive-image {
    max-width: 100%;
    min-width: 80px;
    min-height: 80px;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}

.cell {
    margin: 0.2rem;
}

.cell img {
    display: block;
}

.max-sponsor-size {
    max-height: 300px;
}
