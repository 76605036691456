.news-border {
    margin: 10px 10px;
    padding: 20px;
    border-radius: 15px 50px;
    border-style: solid;
    width: 100%;
    height: 100%;
    color: #333333;
}

.icon {
    min-width: 64px;
    min-height: 64px;
    max-width: 64px;
    max-height: 64px;
    width: 100%;
}
